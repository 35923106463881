import { zodResolver } from '@hookform/resolvers/zod';
import type { ActionFunctionArgs, LoaderFunctionArgs } from '@remix-run/node';
import { redirect } from '@remix-run/node';
import { Form as RemixForm, useLoaderData, useSubmit } from '@remix-run/react';
import { getApp, initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { type JWTVerifyResult } from 'jose';
import { ChevronRightIcon } from 'lucide-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { namedAction } from 'remix-utils/named-action';
import * as z from 'zod';
import { env } from '~/.server/environment';
import { verifyJwt } from '~/.server/jwt';
import {
  commitSession,
  createSession,
  getSession,
  hasSession,
} from '~/.server/sessions';
import { Button } from '~/components/ui/button';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import { Separator } from '~/components/ui/separator';
import { isFirebaseError } from '~/shared/firebase-errors';
import { logger } from '~/.server/logger';
import { getExpectedJson } from '~/shared/utils';

const loginTokenSchema = z.object({
  token: z.string(),
});

export async function action({ request }: ActionFunctionArgs) {
  const rawRequestData = await getExpectedJson(request);

  const tokenData = loginTokenSchema.safeParse(rawRequestData);

  if (!tokenData.success) {
    logger.error(
      `Received invalid login request: ${tokenData.error.format().token?._errors}`,
    );
    throw redirect('/login');
  }

  const createSessionAndRedirect = async (
    token: JWTVerifyResult,
    fingerprint: string,
  ) => {
    const session = await createSession(token, fingerprint);

    return redirect('/app', {
      headers: {
        'Set-Cookie': await commitSession(session),
      },
    });
  };

  return await namedAction(request, {
    async login() {
      const { success, verifiedToken, fingerprint, errors } = await verifyJwt(
        tokenData.data.token,
        'identity-platform',
      );

      if (!success) {
        logger.error(
          `Error validating identity platform jwt: ${errors?.[0].message}`,
        );
        throw redirect('/login');
      }

      return await createSessionAndRedirect(verifiedToken!, fingerprint);
    },
    async loginGoogleOAuth() {
      const { success, verifiedToken, fingerprint, errors } = await verifyJwt(
        tokenData.data.token,
        'google-oauth',
      );

      if (!success) {
        logger.error(
          `Error validating google oauth jwt: ${errors?.[0].message}`,
        );
        throw redirect('/login');
      }

      return await createSessionAndRedirect(verifiedToken!, fingerprint);
    },
  });
}

export async function loader({ request }: LoaderFunctionArgs) {
  if (await hasSession(request)) {
    await getSession(request);
    throw redirect('/app');
  }

  return {
    firebaseConf: {
      key: env.GIP_API_KEY,
      domain: env.GIP_AUTH_DOMAIN,
      tenantId: env.GIP_TENANT_ID,
    },
  };
}

export default function LoginPage() {
  const loaderData = useLoaderData<typeof loader>();
  const [isLoading, setIsLoading] = React.useState(false);

  const submit = useSubmit();

  const loginFormSchema = z.object({
    email: z.string().email(),
    password: z
      .string()
      .min(2, { message: 'Password must be at least 8 characters' }),
  });

  const loginForm = useForm<z.infer<typeof loginFormSchema>>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  initializeApp(
    {
      apiKey: loaderData.firebaseConf.key,
      authDomain: loaderData.firebaseConf.domain,
    },
    'dvrt',
  );

  const firebaseApp = getApp('dvrt');
  const firebaseAuth = getAuth(firebaseApp);
  firebaseAuth.tenantId = loaderData.firebaseConf.tenantId;

  const provider = new GoogleAuthProvider();

  const signInWithGoogle = () => {
    setIsLoading(true);
    signInWithPopup(firebaseAuth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);

        submit(
          { token: credential!.idToken! },
          {
            action: '?action=loginGoogleOAuth',
            method: 'post',
            encType: 'application/json',
          },
        );
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
      });
  };

  const handleLoginEvent = (data: z.infer<typeof loginFormSchema>) => {
    setIsLoading(true);
    signInWithEmailAndPassword(firebaseAuth, data.email, data.password)
      .then(async (result) => {
        const idToken = await result.user.getIdToken();

        submit(
          { token: idToken },
          {
            action: '?action=login',
            method: 'post',
            encType: 'application/json',
          },
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (isFirebaseError(err)) {
          if (
            err.code === 'auth/invalid-credential' ||
            err.code === 'auth/wrong-password' ||
            err.code === 'auth/user-not-found'
          ) {
            loginForm.setError('email', { message: 'Incorrect email' });
            loginForm.setError('password', { message: 'Incorrect password' });
          }
          // todo: handle more error scenarios here, like 2FA prompts
        }
        console.error(`An error occurred: ${err}`);
      });
  };

  return (
    <>
      <div className="flex flex-col space-y-2 text-center">
        <img
          src="/logo.png"
          alt="company logo"
          className="mb-10 w-[300px] min-w-[250px] lg:hidden"
          draggable="false"
        />
        <h1 className="text-2xl font-semibold tracking-tight">Login to dvrt</h1>
        <p className="text-sm text-muted-foreground">
          Enter your credentials to login with your account
        </p>
      </div>
      <div className="grid gap-6">
        <Form {...loginForm}>
          <RemixForm
            onSubmit={loginForm.handleSubmit(handleLoginEvent)}
            method="POST"
          >
            <div className="grid gap-4">
              <FormField
                name={'email'}
                control={loginForm.control}
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Username</FormLabel>
                    <Input
                      {...field}
                      type={'email'}
                      autoComplete="email"
                      autoCorrect="off"
                      disabled={isLoading}
                    ></Input>
                    <FormMessage />
                  </FormItem>
                )}
              ></FormField>
              <FormField
                name={'password'}
                control={loginForm.control}
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <Input
                      {...field}
                      type={'password'}
                      autoComplete={'off'}
                      autoCorrect="off"
                      disabled={isLoading}
                    ></Input>
                    <FormMessage />
                  </FormItem>
                )}
              ></FormField>

              <Button disabled={isLoading}>
                <div className="flex flex-row items-center justify-center align-middle">
                  Sign In with Email
                  <ChevronRightIcon className="m-1" />
                </div>
              </Button>
            </div>
          </RemixForm>
        </Form>
        <Separator />
        <Button onClick={signInWithGoogle} disabled={isLoading}>
          <div className="flex flex-row items-center justify-center align-middle">
            Sign In with Google
            <ChevronRightIcon className="m-1" />
          </div>
        </Button>
      </div>
    </>
  );
}
